<template>
  <v-container fluid class="px-0 blue-background">
    {{ $t('Oops!') }}

    {{ $t("The content you're looking for does not exists.") }}
  </v-container>
</template>

<script>
export default {
  components: {
  },
  computed: {},
  data: () => ({}),
  async created() {
  },
  methods: {},
};
</script>

<style scoped lang="scss">

</style>
